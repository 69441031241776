import React from "react"
import tw from "twin.macro"
import { Link } from "gatsby"
import { Container as ContainerBase } from "./misc/Layouts.jsx"
import FacebookIcon from "../images/facebook-logo.svg"
import TwitterIcon from "../images/twitter-logo.svg"
import { StaticImage } from "gatsby-plugin-image"

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24 flex items-center justify-center flex-col `
const LogoContainer = tw.div`flex items-center justify-center md:justify-start`
const AssociationContainer = tw.div`mt-10 flex flex-row`
const AssociationLink = tw(
  Link
)`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`
const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const FooterLink = tw(
  Link
)`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`
const SocialLinksContainer = tw.div`mt-10`
const SocialImg = tw.img`w-8`
const SocialLink = tw(
  Link
)`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`
const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600 inline`

export default () => {
  return (
    <Container>
      <Content>
        <LogoContainer>
          <StaticImage src="../images/rosso-logo.svg" />
        </LogoContainer>
        <AssociationContainer>
          <AssociationLink
            to="https://www.jdla.org/membership/#membership_No01"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="AssociationLink"
          >
            <StaticImage src="../images/jdla-logo.jpg" width={100} />
          </AssociationLink>
          <AssociationLink
            to="https://partners.amazonaws.com/partners/001E000001QMGkSIAX/Rosso%20Inc."
            target="_blank"
            rel="noopener noreferrer"
            aria-label="AssociationLink"
          >
            <StaticImage
              src="../images/aws-consulting-partner.png"
              width={250}
            />
          </AssociationLink>
          <AssociationLink
            href="https://isms.jp/isms/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="AssociationLink"
          >
            <StaticImage src="../images/ISMS認証ロゴ.png" width={200} />
          </AssociationLink>
          <AssociationLink
            href="https://privacymark.jp/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="AssociationLink"
          >
            <StaticImage src="../images/privacy-mark.png" width={100} />
          </AssociationLink>
        </AssociationContainer>
        <LinksContainer>
          <FooterLink
            to="https://www.rosso-tokyo.co.jp/information/#overview"
            target="_blank"
            rel="noopener noreferrer"
          >
            会社概要
          </FooterLink>
          <FooterLink to="/contact/">お問い合わせ</FooterLink>
          <FooterLink to="/">サクッとシリーズ</FooterLink>
          <FooterLink to="/ai/">サクッとAI</FooterLink>
          <FooterLink to="/cloud/">サクッとクラウド</FooterLink>
          <FooterLink
            to="https://www.rosso-tokyo.co.jp/contact/security-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            情報セキュリティポリシー
          </FooterLink>
          <FooterLink
            to="https://www.rosso-tokyo.co.jp/contact/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            プライバシーポリシー
          </FooterLink>
        </LinksContainer>
        <SocialLinksContainer>
          <SocialLink
            to="http://www.facebook.com/share.php?u=https://www.rosso-solution.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialImg src={FacebookIcon} alt="facebook" />
          </SocialLink>
          <SocialLink
            to="http://twitter.com/share?url=https://www.rosso-solution.com/&text=AIとクラウドの導入をもっと手軽に！｜サクッとAI、サクッとクラウド&via=rosso_tokyo"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialImg src={TwitterIcon} alt="twitter" />
          </SocialLink>
        </SocialLinksContainer>
        <CopyrightText>
          <>
            当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を使用しています。このGoogleアナリティクスはデータの収集のためにCookieを使用しています。
            <br />
            このデータは匿名で収集されており、個人を特定するものではありません。
            <br />
            この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
            <br />
            この規約に関しての詳細は
            <a
              href="https://marketingplatform.google.com/about/analytics/terms/jp/"
              rel="noopener noreferrer"
              target="_blank"
              style={{ color: "blue" }}
            >
              Googleアナリティクスサービス利用規約
            </a>
            のページや
            <a
              href="https://policies.google.com/technologies/ads?hl=ja"
              rel="noopener noreferrer"
              target="_blank"
              style={{ color: "blue" }}
            >
              Googleポリシーと規約
            </a>
            ページをご覧ください。
          </>
        </CopyrightText>
      </Content>
    </Container>
  )
}
