import React from "react"
import feather from "feather-icons"

export default (name, sizeArray) => {
  const featherString = feather.icons[name].toSvg({
    width: sizeArray[0],
    height: sizeArray[1],
  })
  return <div dangerouslySetInnerHTML={{ __html: featherString }} />
}
