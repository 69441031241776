import React, { useEffect } from "react"
import tw from "twin.macro"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500  p-8 `

function AnimationReveal({ disabled, children }) {
  if (disabled) {
    return <>{children}</>
  }

  if (!Array.isArray(children)) children = [children]

  const childrenWithAnimation = children.map((child, i) => {
    return <AnimatedFadeInComponent key={i}>{child}</AnimatedFadeInComponent>
  })
  return <>{childrenWithAnimation}</>
}

function AnimatedFadeInComponent({ children }) {
  const fadeInUp = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  }

  const [ref, inView] = useInView({
    threshold: [0.25],
    triggerOnce: true,
  })
  const controls = useAnimation()

  useEffect(() => {
    console.log(inView)
    if (inView) {
      controls.start("visible")
    } else {
      controls.start("hidden")
    }
  }, [controls, inView])

  return (
    <motion.section
      initial="hidden"
      animate={controls}
      variants={fadeInUp}
      ref={ref}
    >
      {children}
    </motion.section>
  )
}

export default props => (
  <StyledDiv className="App">
    <AnimationReveal {...props} />
  </StyledDiv>
)
